.top-info {
  max-width: 1920px;
  margin: auto;
  padding-top: 145px;
  padding-bottom: 60px;
  overflow: hidden;

  color: #ffffff;

  background: url("../img/header-bg.jpg") center 65px no-repeat;

  &__wrap {
    position: relative;
  }

  &__list {
    width: 480px;
    margin-left: 50px;
  }

  &__li {
    height: 77px;
    margin-bottom: 50px;
    padding-left: 110px;

    font-size: 24px;

    &:nth-of-type(1) {
      transition-delay: 0.2s;
    }
    &:nth-of-type(2) {
      transition-delay: 0.4s;
    }
    &:nth-of-type(3) {
      transition-delay: 0.6s;
    }

    strong {
      font-weight: 900;
    }

    span {
      display: block;
      width: 100%;
      margin-top: 10px;

      font-size: 16px;
    }

    &:nth-of-type(1) {
      padding-top: 12px;

      background: url("../img/hand.png") 15px center no-repeat;
    }
    &:nth-of-type(2) {
      background: url("../img/butterfly.png") center left no-repeat;
    }
    &:nth-of-type(3) {
      padding-top: 10px;

      background: url("../img/bottle.png") 15px center no-repeat;
    }
  }

  h1 {
    width: 770px;
    margin-top: 0;
    margin-bottom: 180px;
    margin-left: 155px;

    font-weight: 900;
    font-size: 50px;
    text-align: center;
  }
  p {
    width: 520px;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 30px;

    font-size: 24px;
    text-align: center;

    a {
      display: block;
      margin-top: 10px;

      font-weight: 900;
      color: #ffffff;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  #mushroom1 {
    position: absolute;
    top: 106px;
    left: -10px;

    width: 63px;
    height: 60px;

    background: url("../img/mushroom1.png");

    transition: all 0.1s ease;
  }

  #mushroom2 {
    position: absolute;
    top: 150px;
    right: -205px;

    width: 76px;
    height: 72px;

    background: url("../img/mushroom2.png");

    transition: all 0.1s ease;
  }
  #mushroom3 {
    position: absolute;
    right: -150px;
    bottom: -60px;

    width: 51px;
    height: 77px;

    background: url("../img/mushroom3.png");

    transition: all 0.1s ease;
  }
}
@media screen and (max-width: 1169px) {
  .top-info {
    h1 {
      margin-left: 50px;
    }
    &__li {
      margin-bottom: 40px;
    }
    #mushroom1 {
      top: 165px;
    }

    #mushroom2 {
      top: 110px;
      right: -25px;
    }
    #mushroom3 {
      right: 340px;
      bottom: -60px;
    }
  }
}
@media screen and (max-width: 969px) {
  .top-info {
    padding-top: 70px;
    padding-bottom: 40px;

    background: url("../img/header-bg960.jpg") center 50px no-repeat;
    h1 {
      width: 100%;
      margin-bottom: 140px;
      margin-left: 0;

      font-size: 40px;
    }
    &__list {
      width: 350px;
      margin-left: 0;
    }
    &__li {
      margin-bottom: 7px;
      padding-left: 90px;

      font-size: 16px;
      span {
        font-size: 14px;
      }
      &:nth-of-type(2) {
        padding-top: 15px;
      }

      &:nth-of-type(3) {
        padding-top: 20px;
      }
    }
    #mushroom1,
    #mushroom2,
    #mushroom3 {
      display: none;
    }
    p {
      width: 385px;
      margin-left: 0;

      font-size: 18px;
    }
  }
}
@media screen and (max-width: 749px) {
  .top-info {
    padding-bottom: 390px;

    background: url("../img/header-bg750.jpg") center 50px no-repeat;
    h1 {
      margin-bottom: 15px;

      font-size: 35px;
    }
    &__list {
      width: 310px;
      margin-right: auto;
      margin-bottom: 50px;
      margin-left: auto;
    }
    &__li {
      height: 65px;
      padding-left: 65px;
      &:nth-of-type(1) {
        background-size: 35px, 55px;
      }
      &:nth-of-type(2) {
        background-size: 60px, 47px;
      }
      &:nth-of-type(3) {
        background-size: 42px, 50px;
      }
    }
    p {
      width: 100%;
    }
  }
}
@media screen and (max-width: 479px) {
  .top-info {
    h1 {
      font-size: 25px;
    }
    &__list {
      width: 100%;
    }
    &__li {
      height: 60px;
      padding-left: 55px;
      &:nth-of-type(1) {
        background-size: 25px, 35px;
      }
      &:nth-of-type(2) {
        background-size: 40px, 30px;
      }
      &:nth-of-type(3) {
        background-size: 25px, 35px;
      }
      span {
        font-size: 12px;
      }
    }
  }
}
