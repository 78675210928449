@mixin clearfix() {
  &:before,
  &:after {
    content: "";

    display: table;
    clear: both;
  }
}

@mixin min-max($min-width, $max-width, $padding) {
  box-sizing: border-box;
  min-width: $min-width;
  max-width: $max-width;
  margin: 0 auto;
  padding: 0 $padding;
}

@mixin adaptive-video() {
  position: relative;

  height: 0;
  padding-top: 30px;
  padding-bottom: 56.25%; /* задаёт высоту контейнера для 16:9 (если 4:3 — поставьте 75%) */
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
  }
}

@mixin visually-hidden() {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;

  white-space: nowrap;

  border: 0;

  clip: rect(0, 0, 0, 0);
  clip-path: inset(100%);
}

@function px-to-rem($size, $basicSize: 16px) {
  @return $size / $basicSize#{rem};
}

@function max($numbers...) {
  @return m#{a}x(#{$numbers});
}

@function min($numbers...) {
  @return m#{i}n(#{$numbers});
}
