.rotation {
  position: relative;
  z-index: 9998;

  margin-bottom: -45px;
  &__wrap {
    position: relative;

    width: 770px;
    margin: auto;
  }
  &__img {
    position: relative;

    width: 300px;
    margin: auto;

    text-align: center;
    &:before {
      content: "";
      position: absolute;
      top: 33px;
      left: 8px;
      z-index: -1;

      display: block;
      width: 284px;
      height: 284px;

      background: url("../img/om-mane-2.png") center center no-repeat;

      animation-name: rotation;
      animation-duration: 25s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
  }

  &__text {
    position: absolute;
    top: 50%;

    font-weight: 300;

    transform: translateY(-50%);
    opacity: 0.3;
    &--left {
      left: 0;
    }
    &--right {
      right: 0;
    }
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.effects {
  position: relative;

  padding-top: 50px;
  p {
    max-width: 1030px;
    margin-right: auto;
    margin-left: auto;

    text-align: center;
    span {
      font-weight: 500;
    }
  }
  &__title {
    color: #ffffff;
    h2 {
      font-weight: 300;
    }
  }
}
@media screen and (max-width: 1169px) {
  .effects {
    padding-bottom: 10px;
  }
}
@media screen and (max-width: 969px) {
  .rotation {
    &__wrap {
      width: 750px;
    }
    &__text {
      &--left {
        left: 15px;
      }
      &--right {
        right: 15px;
      }
    }
  }
}
@media screen and (max-width: 749px) {
  .rotation {
    &__wrap {
      width: 480px;
    }
    &__text {
      display: none;
    }
  }
}
@media screen and (max-width: 479px) {
  .rotation {
    &__wrap {
      width: 320px;
    }
  }
}
