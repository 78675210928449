.flash {
  position: relative;

  color: #ffffff;

  &__wrap {
    position: relative;
  }
  &__sm {
    width: 760px;
    margin: auto;
    margin-bottom: 30px;

    font-weight: 300;
    font-size: 16px;
    text-align: center;
  }
  &__md {
    width: 760px;
    margin: auto;
    margin-bottom: 30px;

    font-weight: 900;
    font-size: 30px;
    text-align: center;
  }

  &__title {
    width: 980px;
    margin: auto;
    h2 {
      color: #ffffff;
    }
  }
  &.visible {
    .girl-img,
    .flash-img {
      transform: translateY(0);
      opacity: 1;
    }
  }
}
.girl-img {
  position: absolute;
  top: -220px;
  left: -200px;

  width: 336px;
  height: 844px;

  background: url("../img/girl-bg.png");
  transform: translateY(844px);
  opacity: 0;

  transition: 0.7s ease;
}
.flash-img {
  position: absolute;
  top: -220px;
  right: -300px;

  width: 449px;
  height: 696px;

  background: url("../img/flash-bg.png");
  transform: translateY(-700px);
  opacity: 0;

  transition: 0.7s ease;
}
@media screen and (max-width: 1169px) {
  .flash {
    padding-bottom: 10px;
    &__wrap {
      width: 760px;
      margin-right: 25px;
    }
    &__title {
      width: 100%;
    }
    &__md,
    &__sm {
      width: 100%;
    }
    .girl-img {
      left: -310px;
    }
    .flash-img {
      right: -390px;
    }
  }
}
@media screen and (max-width: 969px) {
  .flash {
    &__wrap {
      width: 550px;
      margin-right: auto;
    }
    .girl-img {
      top: -45px;
      left: -200px;

      width: 220px;
      height: 554px;

      background-size: cover;
    }
    .flash-img {
      top: -35px;
      right: -210px;

      width: 213px;
      height: 600px;

      background-size: cover;
    }
  }
}
@media screen and (max-width: 749px) {
  .flash {
    &__wrap {
      width: 480px;
    }
    &__md,
    &__sm {
      width: 290px;
      margin-right: 10px;
      margin-left: auto;
    }
    &__md {
      font-size: 20px;
    }
    .girl-img {
      top: 0;
      left: 0;
    }
    .flash-img {
      top: 0;
      right: -135px;
    }
  }
}
@media screen and (max-width: 479px) {
  .flash {
    &__wrap {
      width: 320px;
    }
    &__title,
    &__sm,
    &__md {
      width: 240px;
      margin-right: 0;
    }
    &__sm {
      &:last-of-type {
        width: 100%;
      }
    }
    .girl-img {
      top: -65px;
      left: -65px;

      width: 146px;
      height: 580px;

      background: url("../img/girl-sm.png");
    }
  }
}
