.footer {
  color: #ffffff;

  background: #000000;
  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 78px;
  }
}
.copy {
  display: flex;
  align-items: center;

  font-weight: 300;
  a {
    margin-left: 15px;
  }
}
.pravo {
  font-weight: 300;
  color: #ffffff;
  &:hover {
    text-decoration: none;
  }
}
#pravo {
  display: none;
}
@media screen and (max-width: 1169px) {
  .pravo {
    width: 110px;
    margin-right: 20px;
    margin-left: 20px;
  }
}
@media screen and (max-width: 969px) {
  .footer {
    padding-top: 10px;
    padding-bottom: 10px;
    &__wrap {
      flex-wrap: wrap;
    }
  }
  .copy {
    flex-shrink: 0;
    width: 100%;

    text-align: center;
  }
  .pravo {
    width: 200px;
    margin-left: 160px;
  }
  .li {
    margin-right: 45px;
  }
}
@media screen and (max-width: 749px) {
  .footer {
    &__wrap {
      flex-direction: column;
      height: auto;
    }
    .copy {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      a {
        margin-top: 10px;
        margin-left: 0;
      }
    }
    .pravo {
      display: block;
      width: 100%;
      margin: 0;
      margin-bottom: 15px;

      text-align: center;
    }
    .li {
      margin-right: 0;
    }
  }
}
