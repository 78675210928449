@charset "UTF-8";

/**
 * Основные стили
 **/
* {
  box-sizing: border-box;
}
html {
  font-size: 16px;
}

body {
  overflow-x: hidden;

  font-family: "Montserrat", sans-serif;
  word-wrap: break-word;

  overflow-wrap: break-word;
}

.container {
  @include min-max(320px, 100%, 0);
}

.wrap {
  max-width: 1170px;
  margin: auto;
  padding-right: 15px;
  padding-left: 15px;
}
.section-title {
  text-align: center;
  color: #000000;
  h2 {
    margin: 0;
    margin-bottom: 35px;

    font-weight: 900;
    font-size: 40px;
  }
}
.section-subtitle {
  margin-bottom: 60px;

  font-weight: 300;
  font-size: 30px;
  text-align: center;
}
p {
  margin-top: 20px;
  margin-bottom: 20px;

  line-height: 1.4;
  &.light {
    opacity: 0.5;
  }
}

.red {
  color: #c21146;
}
.blue {
  color: #02f4ef;
}
.skew {
  position: relative;

  color: #ffffff;

  background: #000000;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    z-index: -1;

    width: 100%;
    height: 100%;

    background: inherit;
    transform: skewY(-3deg);
    transform-origin: right top;
  }
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    z-index: -1;

    width: 100%;
    height: 100%;

    background: inherit;
    transform: skewY(-3deg);
    transform-origin: left bottom;
  }
}

.animated {
  transform: translateY(-150px);
  opacity: 0;

  transition: 1s ease;
  &.visible {
    transform: translateY(0);
    opacity: 1;
  }
}

@media screen and (max-width: 1169px) {
  .wrap {
    width: 970px;
  }
  .section-title {
    h2 {
      font-size: 30px;
    }
  }
}
@media screen and (max-width: 969px) {
  .wrap {
    width: 750px;
  }
  .animated {
    transform: translateY(0);
    opacity: 1;
  }
}
@media screen and (max-width: 749px) {
  .wrap {
    width: 480px;
  }
  .section-title {
    h2 {
      font-size: 25px;
    }
  }
}
@media screen and (max-width: 479px) {
  .wrap {
    width: 320px;
  }
}
