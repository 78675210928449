.legal {
  padding-top: 70px;
  padding-bottom: 130px;
  &__text {
    width: 750px;
    margin-left: auto;

    font-size: 14px;
    text-align: center;
    .small-text {
      font-weight: 300;
      font-size: 14px;
    }
    span {
      font-weight: 500;
    }
  }
}
.block-cols {
  display: flex;
  justify-content: space-between;
  margin-bottom: 45px;

  &__col {
    width: 362px;
    &-img {
      margin-bottom: 20px;
      img {
        max-width: 100%;
        height: auto;
      }
    }
    &-p {
      height: 70px;
      margin-bottom: 15px;

      font-weight: 300;
      font-size: 20px;
      line-height: 1.3;
      text-align: center;
      span {
        font-weight: 500;
        color: #c21146;
      }
    }
  }
  a {
    position: relative;

    display: block;
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;

      width: 80px;
      height: 80px;

      background-image: url("../img/play.png");
      transform: translate(-50%, -50%);
      opacity: 0.7;

      transition: 0.3s ease;
    }
    &:hover:after {
      opacity: 1;
    }
  }
  .small-text {
    font-weight: 300;
    font-size: 16px;
    text-align: center;
  }
}
@media screen and (max-width: 1169px) {
  .block-cols {
    &__col {
      width: 300px;
      &-p {
        font-size: 18px;
      }
    }
  }
  .legal {
    &__text {
      width: 620px;
    }
  }
}
@media screen and (max-width: 969px) {
  .legal {
    padding-top: 30px;
    padding-bottom: 60px;
    &__text {
      width: 100%;
      margin-left: 0;
      .small-text {
        width: 660px;
        margin: auto;
      }
    }
  }
  .block-cols {
    flex-direction: column;
    width: 660px;
    margin: auto;
    &__col {
      display: flex;
      width: 100%;
      margin-bottom: 30px;
      &-img {
        flex-shrink: 0;
        width: 300px;
        margin-right: 25px;
        margin-bottom: 0;
      }
      &-p {
        height: auto;
        margin-bottom: 5px;
        br {
          display: none;
        }
      }
    }
    .small-text {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}
@media screen and (max-width: 749px) {
  .block-cols {
    width: 100%;

    &__col {
      flex-direction: column;
      &-img {
        margin-right: auto;
        margin-bottom: 15px;
        margin-left: auto;
      }
      &-p {
        height: auto;
        margin-bottom: 5px;
        br {
          display: none;
        }
      }
    }
    .small-text {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  .legal {
    &__text {
      .small-text {
        width: 100%;
      }
    }
  }
}
