.slideout-menu {
  display: none;
  padding: 20px;

  background: #000000;
  ul {
    margin: 0;
    padding: 0;

    list-style-type: none;
    a {
      display: block;
      margin-bottom: 15px;

      color: #ffffff;
      text-decoration: none;
    }
  }
}
@media screen and (max-width: 969px) {
  .slideout-menu {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 0;

    display: none;
    width: 256px;
    min-height: 100vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .slideout-menu-left {
    left: 0;
  }

  .slideout-menu-right {
    right: 0;
  }

  .slideout-panel {
    position: relative;
    z-index: 1;

    min-height: 100vh;

    background-color: #ffffff; /* A background-color is required */

    will-change: transform;
  }

  .slideout-open,
  .slideout-open body,
  .slideout-open .slideout-panel {
    overflow: hidden;
  }

  .slideout-open .slideout-menu {
    display: block;
  }
  .panel:before {
    content: "";

    display: block;

    background-color: rgba(0, 0, 0, 0);

    transition: background-color 0.5s ease-in-out;
  }

  .panel-open:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 99;

    width: 100%;

    background-color: rgba(0, 0, 0, 0.5);
  }
}
