.fixed-menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;

  width: 100%;
  height: 70px;

  background-color: black;

  &:before {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;

    height: 1px;

    background: #32ffea;
  }

  &:after {
    content: "";
    position: absolute;
    right: 0;
    bottom: -1px;
    left: 0;

    height: 1px;

    background: #f61458;
  }

  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
  }
}

.logo {
  height: 27px;
  margin-left: 15px;
  padding-left: 55px;

  font-weight: 300;
  font-size: 18px;
  line-height: 27px;

  background: url("../img/logo.svg") left center no-repeat;
  a {
    color: #ffffff;
    text-decoration: none;
  }
}

.topmenu {
  ul {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;

    list-style: none;

    a {
      display: block;
      padding-top: 19px;
      padding-right: 16px;
      padding-bottom: 19px;
      padding-left: 16px;

      font-weight: 500;
      font-size: 14px;
      text-align: center;
      color: #cccccc;
      text-decoration: none;

      transition: 0.3s ease;
      &:hover {
        color: #ffffff;
      }
    }
  }
}
.ham {
  position: relative;

  display: none;
  width: 28px;
  height: 17px;
  span {
    position: absolute;
    top: 7px;

    width: 100%;
    height: 3px;
    margin: 0 auto;

    background: #ffffff;
  }
  &:before,
  &:after {
    content: "";
    position: absolute;
    right: 0;
    left: 0;

    height: 3px;

    background-color: #ffffff;
  }
  &::before {
    top: 0;
  }
  &::after {
    bottom: 0;
  }
  &.active {
    span {
      opacity: 0;

      transition: 0.5s;
    }
    &:before {
      top: 10px;

      transform: rotate(45deg);

      transition: 0.4s;
    }
    &:after {
      bottom: 5px;

      transform: rotate(-45deg);

      transition: 0.4s;
    }
  }
}

@media screen and (max-width: 1169px) {
  .logo {
    margin-left: 0;
    padding-left: 50px;
  }
  .topmenu {
    ul {
      li {
        &:nth-of-type(3) {
          display: none;
        }
      }
      a {
        padding-right: 12px;
        padding-left: 12px;
      }
    }
  }
}
@media screen and (max-width: 969px) {
  .topmenu {
    display: none;
  }
  .ham {
    display: block;
  }
  .fixed-menu {
    height: 50px;
    &__wrap {
      height: 50px;
    }
  }
}
