.research {
  padding-top: 110px;
  padding-bottom: 60px;
  &__wrap {
    width: 980px;
    margin: auto;
  }
  p {
    font-weight: 300;
    text-align: center;
    span {
      font-weight: 500;
    }
    a {
      color: #000000;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.prefs {
  display: flex;
  flex-wrap: wrap;
  margin-top: 65px;
  margin-bottom: 65px;

  &__item {
    width: 20%;
    padding-top: 65px;
    padding-right: 20px;
    padding-left: 20px;

    font-weight: 300;
    font-size: 15px;
    text-align: center;

    &--psor {
      margin-bottom: 60px;

      background: url("../img/psor.svg") top center no-repeat;
    }
    &--alco {
      background: url("../img/alco.svg") top center no-repeat;
    }
    &--nastr {
      background: url("../img/nastr.svg") top center no-repeat;
    }
    &--son {
      background: url("../img/son.svg") top center no-repeat;
    }
    &--sust {
      background: url("../img/sust.svg") top center no-repeat;
    }
    &--nogt {
      background: url("../img/nogt.svg") top center no-repeat;
    }
    &--migr {
      background: url("../img/migr.svg") top center no-repeat;
    }
    &--menst {
      background: url("../img/menstr.svg") top center no-repeat;
    }
    &--create {
      background: url("../img/create.svg") top center no-repeat;
    }
    &--park {
      background: url("../img/park.svg") top center no-repeat;
    }
  }
}
@media screen and (max-width: 1169px) {
  .research {
    padding-top: 80px;
  }
}
@media screen and (max-width: 969px) {
  .research {
    padding-bottom: 10px;
    &__wrap {
      width: 750px;
    }
  }
  .prefs {
    justify-content: center;
    margin-bottom: 10px;
    &__item {
      width: 25%;
      margin-bottom: 35px;
    }
  }
}
@media screen and (max-width: 749px) {
  .research {
    &__wrap {
      width: 480px;
    }
  }
  .prefs {
    margin-top: 15px;
    &__item {
      width: 50%;
    }
  }
}
@media screen and (max-width: 479px) {
  .research {
    padding-top: 40px;
    &__wrap {
      width: 320px;
    }
  }
  .prefs {
    &__item {
      width: 100%;
    }
  }
}
