.reviews {
  padding-top: 40px;
  &__title {
    h2 {
      color: #ffffff;
    }
  }
  &__subtitle {
    width: 820px;
    margin-right: auto;
    margin-bottom: 60px;
    margin-left: auto;

    font-weight: 300;
    font-size: 30px;
    text-align: center;
    color: #ffffff;
    span {
      font-weight: 500;
    }
  }
}

.review-slider {
  position: relative;

  width: 555px;
  margin-right: auto;
  margin-bottom: 75px;
  margin-left: auto;
}
.review-item {
  display: flex;
  width: 555px;
  margin-bottom: 30px;
  padding: 25px;

  color: #000000;

  background: #ffffff;
  &:nth-of-type(3) {
    margin-bottom: 0;
  }
  &__img {
    margin-right: 15px;
  }
  &__info {
    display: flex;
    margin-bottom: 10px;

    font-weight: 300;
    font-size: 14px;
  }
  &__author {
    margin-right: 10px;
  }
  &__date {
    color: #808080;
  }
  &__text {
    margin-bottom: 15px;

    font-size: 14px;
    line-height: 1.4;
  }
  &__likes {
    position: relative;

    padding-left: 23px;

    font-size: 14px;
    color: #606060;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;

      width: 16px;
      height: 14px;

      background: url("../img/like-plus.svg");
    }
    &:after {
      content: "";
      position: relative;
      top: 2px;

      display: inline-block;
      width: 16px;
      height: 14px;
      margin-left: 15px;

      background: url("../img/like-minus.svg");
    }
  }
}
.slick-arrow {
  position: absolute;
  top: 50%;

  width: 48px;
  height: 48px;

  background: #ffffff;
  border: none;
  border-radius: 50%;
  transform: translateY(-50%);
  cursor: pointer;

  transition: 0.3s ease;
  &:hover {
    &:after {
      background-color: #d20405;
    }
  }
}
.slick-prev {
  left: -100px;
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;

    display: block;
    width: 13px;
    height: 25px;

    background-color: #000000;
    transform: translate(-50%, -50%);

    mask-image: url("../img/prev.svg");
  }
}
.slick-next {
  right: -100px;
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;

    display: block;
    width: 13px;
    height: 25px;

    background-color: #000000;
    transform: translate(-50%, -50%);

    mask-image: url("../img/next.svg");
  }
}
.tg {
  width: 445px;
  margin-right: auto;
  margin-left: auto;

  text-align: center;
  img {
    margin-bottom: 75px;
  }
  p {
    margin-top: 0;
    margin-bottom: 60px;

    font-weight: 500;
    font-size: 25px;
    a {
      display: block;
      margin-top: 15px;

      font-weight: 300;
      color: #ffffff;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__list {
    margin: 0;
    padding: 0;

    list-style: none;
    li {
      position: relative;

      margin-bottom: 20px;
      padding-left: 25px;

      line-height: 1.4;
      text-align: left;
      &:before {
        content: "—";
        position: absolute;
        top: 0;
        left: 0;

        margin-right: 5px;
      }
    }
  }
}
@media screen and (max-width: 1169px) {
  .reviews {
    padding-bottom: 10px;
  }
}
@media screen and (max-width: 969px) {
  .reviews {
    &__subtitle {
      width: 100%;

      font-size: 25px;
    }
  }
  .slick-prev {
    left: -65px;
  }
  .slick-next {
    right: -65px;
  }
}
@media screen and (max-width: 749px) {
  .reviews {
    &__subtitle {
      font-size: 20px;
    }
  }
  .review-slider {
    width: 375px;
  }
  .review-item {
    width: 375px;
  }
  .slick-prev {
    left: -55px;
  }
  .slick-next {
    right: -55px;
  }
  .tg {
    img {
      margin-bottom: 25px;
    }
    p {
      margin-bottom: 25px;

      font-size: 20px;
    }
  }
}
@media screen and (max-width: 479px) {
  .slick-prev,
  .slick-next {
    display: none !important;
  }
  .review-slider {
    width: 100%;
  }
  .review-item {
    width: 100%;
  }
  .tg {
    width: 100%;
    img {
      max-width: 100%;
    }
  }
}
