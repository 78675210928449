.photos {
  padding-top: 110px;
  padding-bottom: 80px;
  &__subtitle {
    width: 800px;
    margin-right: auto;
    margin-left: auto;
  }
  p {
    font-weight: 500;
    font-size: 30px;
    text-align: center;
    a {
      display: block;

      font-weight: 300;
      color: #000000;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.gallery {
  display: flex;
  flex-wrap: wrap;
  &-item {
    width: 360px;
    margin-right: 30px;
    margin-bottom: 20px;

    font-weight: 300;
    text-align: center;

    &:nth-of-type(3n) {
      margin-right: 0;
    }
    &:hover img {
      opacity: 0.85;
    }
    a {
      display: block;

      img {
        max-width: 100%;
        height: auto;
        margin-bottom: 10px;

        transition: 0.3s ease;
      }
    }
  }
}
@media screen and (max-width: 1169px) {
  .gallery {
    &-item {
      width: 300px;
      margin-right: 20px;
    }
  }
}
@media screen and (max-width: 969px) {
  .photos {
    padding-top: 70px;
    padding-bottom: 20px;
    &__subtitle {
      width: 100%;
      margin-bottom: 20px;

      font-size: 25px;
    }
    p {
      font-size: 25px;
    }
  }
  .gallery {
    width: 620px;
    margin: auto;
    &-item {
      &:nth-of-type(3n) {
        margin-right: 20px;
      }
      &:nth-of-type(2n) {
        margin-right: 0;
      }
    }
  }
}
@media screen and (max-width: 749px) {
  .photos {
    &__subtitle {
      font-size: 20px;
    }
    p {
      font-size: 20px;
    }
  }
  .gallery {
    width: 290px;
    &-item {
      width: 100%;
      margin-right: 0;
      &:nth-of-type(3n) {
        margin-right: 0;
      }
    }
  }
}
@media screen and (max-width: 479px) {
  .photos {
    padding-top: 35px;
    padding-bottom: 35px;
  }
  .gallery {
    width: 290px;
    &-item {
      width: 100%;
      margin-right: 0;
      &:nth-of-type(3n) {
        margin-right: 0;
      }
    }
  }
}
