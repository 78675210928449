.where {
  margin-bottom: 50px;
  padding-top: 80px;

  p {
    width: 870px;
    margin-right: auto;
    margin-bottom: 35px;
    margin-left: auto;

    font-weight: 500;
    font-size: 30px;
    text-align: center;
    a {
      display: block;

      font-weight: 300;
      color: #000000;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__list {
    width: 970px;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 50px;
    padding-left: 430px;

    background: url("../img/granny.jpg") left top no-repeat;
    &-p {
      padding-top: 25px;

      font-weight: 300;
      font-size: 20px;
      color: #d20405;
      span {
        font-weight: 900;
      }
      &--sm {
        font-size: 16px;
        color: #757575;
      }
    }
    ul {
      margin: 0;
      margin-top: 30px;
      margin-bottom: 40px;
      margin-left: 35px;
      padding: 0;
      li {
        margin-bottom: 20px;

        font-weight: 300;
        &::marker {
          color: #d20405;
        }
      }
    }
  }
}
@media screen and (max-width: 969px) {
  .where {
    margin-bottom: 0;
    p {
      width: 100%;

      font-size: 25px;
    }
    &__list {
      width: 100%;
      padding-left: 0;

      background: none;

      ul {
        padding-left: 330px;

        background: url(../img/granny.jpg) left top no-repeat;
        background-size: 250px, 350px;
        li {
          margin-bottom: 22px;
        }
      }
      &-p {
        width: 510px;
        margin-right: auto;
        margin-left: auto;

        text-align: center;
        &--sm {
          width: 500px;
          margin: auto;

          text-align: center;
        }
      }
    }
  }
}
@media screen and (max-width: 749px) {
  .where {
    padding-top: 45px;
    p {
      font-size: 20px;
    }
    &__list {
      padding-top: 350px;

      background: url(../img/granny.jpg) center top no-repeat;
      background-size: 250px, 350px;
      &-p {
        width: 100%;
        padding-top: 0;
        &--sm {
          width: 100%;
        }
      }
      ul {
        padding-left: 0;

        background: none;
      }
    }
  }
}
