.alterstate {
  padding-top: 110px;
  padding-bottom: 100px;
  &__title {
    width: 770px;
    margin-right: auto;
    margin-left: auto;
  }
  &__subtitle {
    width: 770px;
    margin-right: auto;
    margin-bottom: 60px;
    margin-left: auto;

    font-weight: 300;
    font-size: 30px;
    text-align: center;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
    margin-right: auto;
    margin-bottom: 60px;
    margin-left: auto;
  }

  p {
    max-width: 1020px;
    margin-right: auto;
    margin-left: auto;

    font-weight: 300;
    text-align: center;
    span {
      font-weight: 500;
    }
  }
}
@media screen and (max-width: 969px) {
  .alterstate {
    padding-top: 70px;
    padding-bottom: 60px;
    &__title,
    &__subtitle {
      width: 100%;
    }
    &__subtitle {
      font-size: 25px;
    }
  }
}
@media screen and (max-width: 749px) {
  .alterstate {
    &__subtitle {
      margin-bottom: 25px;

      font-size: 20px;
    }
  }
}
@media screen and (max-width: 749px) {
  .alterstate {
    padding-top: 40px;
    padding-bottom: 30px;
  }
}
