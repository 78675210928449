.how {
  &__title {
    text-align: left;
    color: #ffffff;
  }
  &__wrap {
    width: 900px;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 50px;
    padding-left: 250px;

    background: url("../img/banka.png") left 80px no-repeat;
  }
}
@media screen and (max-width: 969px) {
  .how {
    &__wrap {
      width: 750px;
      padding-top: 20px;
      padding-left: 200px;
    }
  }
}
@media screen and (max-width: 749px) {
  .how {
    &__wrap {
      width: 480px;
      padding-top: 270px;
      padding-bottom: 15px;
      padding-left: 15px;

      background: url("../img/banka.png") center 20px no-repeat;
    }
    &__title {
      text-align: center;
    }
    p {
      text-align: center;
    }
  }
}
@media screen and (max-width: 479px) {
  .how {
    &__wrap {
      width: 320px;
    }
  }
}
